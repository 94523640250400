import React from "react"
import { Container, Flex, Box, Text, Image } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import "./style.scss"
import Icon1 from "../../images/icons/blockchain-consultation.png"
import Icon2 from "../../images/icons/DApp.png"
import Icon3 from "../../images/icons/Decentralized-exchange.png"
import Icon4 from "../../images/icons/DeFi.png"
import Icon5 from "../../images/icons/digital-identitiy.png"

const swiperItems = [
  { icon: Icon1, title: "Web Design & Development" },
  { icon: Icon2, title: "Mobile App Development" },
  { icon: Icon3, title: "Social Media Management" },
  { icon: Icon4, title: "Graphic Designing" },
  { icon: Icon5, title: "Search Engine Optimization" },
]
export default function OurExpertise() {
  const SwipperContent = ({ children }) => {
    return (
      <Box
        textAlign="center"
        bg={"#ffffff"}
        borderRadius="20px"
        px={5}
        py={10}
        boxShadow="0px 12px 108px rgb(0 0 0 / 5%)"
      >
        {children}
      </Box>
    )
  }

  const SwipperIcon = ({ src }) => {
    return (
      <Image
        alt={src}
        fit={"contain"}
        align={"center"}
        margin={"auto"}
        src={src}
      />
    )
  }
  const SwipperTitle = ({ title }) => {
    return (
      <Text as={"h4"} fontWeight="500" mt={"20px"}>
        {title}
      </Text>
    )
  }
  return (
    <Box background={"#F8F8F8"} py={{ base: 20, md: 28 }}>
      <Container maxW={"full"}>
        <Flex
          flex={1}
          align={"center"}
          direction={{ base: "column", md: "row" }}
        >
          <Box w={{ base: "100%", md: "70%" }} px={{ base: 0, md: 15 }}>
            <Text
              as={"h2"}
              lineHeight={1.3}
              fontWeight={700}
              color={"blackAlpha.900"}
              mb={2}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              Our Expertise
            </Text>
            <Text color={"blackAlpha.900"} fontSize="20">
              Our experts provide customized solutions that to meet your
              business objectives using the latest technologies. At Xeverse.io
              our team of developers, designers, software engineers, and mobile
              strategists share the enthusiasm to innovate and experiment with
              new ideas that add life to your projects.
            </Text>
          </Box>
        </Flex>
        <Box my={10}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            spaceBetween={30}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          >
            {swiperItems.map(item => {
              return (
                <SwiperSlide>
                  <SwipperContent>
                    <SwipperIcon src={item.icon} />
                    <SwipperTitle title={item.title} />
                  </SwipperContent>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>
      </Container>
    </Box>
  )
}
