import React, { ReactNode } from "react"
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import "./style.scss"
const Testimonial = ({ children }) => {
  return <Box>{children}</Box>
}
const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  )
}
const TestimonialHeading = ({ children }) => {
  return (
    <Heading as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  )
}

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
      minH="100px"
    >
      {children}
    </Text>
  )
}
const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600} color="#04a073">
          {name}
        </Text>
        <Text fontSize={"sm"} color={"whiteAlpha.800"}>
          {title}
        </Text>
      </Stack>
    </Flex>
  )
}

function Testimonials() {
  return (
    <Box background={"#0a0a0a"} pt={{ base: 50, md: 20 }}>
      <Container maxW={"full"}>
        <Box px={{ base: 10, md: 15 }}>
          <Text
            as="h2"
            fontWeight="700"
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            color={"white"}
            mb="50px"
            textAlign={{ base: "center", md: "left" }}
          >
            Testimonials
          </Text>
        </Box>

        <Swiper
          className="mySwiper"
          auto={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          spaceBetween={30}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          <SwiperSlide>
            <SwiperSlide>
              <Testimonial>
                <TestimonialContent>
                  <TestimonialHeading>Aquatech</TestimonialHeading>
                  <TestimonialText>
                    Xeverse has done a wonderful job redesigning our website to
                    take it from plain and functional to amazingly
                    pronpfessional. We would highly recommend Xeverse to any
                    business who needs to improve their internet traffic or
                    their business.
                  </TestimonialText>
                </TestimonialContent>
              </Testimonial>
            </SwiperSlide>
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Southernblue</TestimonialHeading>
                <TestimonialText>
                  Xeverse is the best digital marketing agency in my opinion.
                  Increased traffic and quality leads in the period of 6 months.
                  Absolutely pleased with Xeverse for SEO & FB marketing. Much
                  better than agencies that I worked with previously.
                </TestimonialText>
              </TestimonialContent>
            </Testimonial>
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Mandob</TestimonialHeading>
                <TestimonialText>
                  Always a pleasure to work with Xeverse. Such professional
                  people and you know you’ll receive a quick, innovative and no
                  fuss service.
                </TestimonialText>
              </TestimonialContent>
            </Testimonial>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Box>
  )
}
export default Testimonials
