import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Testimonials from "../components/Testimonials"
import Blogs from "../components/blogs"
import OurClients from "../components/ourClients"
import OurExpertise from "../components/ourExpertise"
import ContactForm from "../components/contactForm"
import CTA from "../components/callToAction"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <Blogs />
    <OurClients />
    <OurExpertise />
    <Testimonials />
    <CTA />
    <ContactForm />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
