import React from "react"

import {
  Container,
  Stack,
  Flex,
  chakra,
  Box,
  Link,
  Text,
  VisuallyHidden,
  Image,
  Button,
} from "@chakra-ui/react"
import { AiOutlineArrowRight } from "react-icons/ai"
import AOS from "aos"
import "aos/dist/aos.css"

if (typeof document !== "undefined") AOS.init()

export default function CTA() {
  return (
    <Box
      data-aos="fade-up"
      data-aos-duration="3000"
      background={"primary"}
      color="white"
      borderTopWidth={1.5}
      borderColor={"white"}
    >
      <Box py={{ base: 20, md: 28 }}>
        <Container maxW={"full"}>
          <Flex
            flex={1}
            align={"center"}
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Stack px={{ base: 0, md: 15 }} w={"full"}>
              <Text
                as={"h2"}
                color="dark"
                lineHeight="1.3"
                fontWeight={700}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                mb={{ base: "20px" }}
              >
                Have a Project? <br />
                Let’s Talk About It
              </Text>
            </Stack>
            <Button
              rightIcon={<AiOutlineArrowRight />}
              variant="outline"
              color={"#04a073"}
              fontSize="18px"
              p={"25px"}
              fontWeight={"bold"}
              borderWidth="2px"
              borderColor="#04a073"
              w={{ base: "xs", md: "md" }}
              display="flex"
              justifyContent={"space-between"}
              _hover={{ borderColor: "#04a073", color: "#04a073" }}
            >
              Let's build together
            </Button>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
