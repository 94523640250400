import React from "react"
import {
  Container,
  Flex,
  Box,
  Text,
  Image,
  Wrap,
  WrapItem,
  SimpleGrid,
} from "@chakra-ui/react"
// import { Clients } from "../../constants"

import "./style.scss"

import Picture1 from "../../images/clients/Picture1-1.png"
import Picture2 from "../../images/clients/Picture2-1.png"
import Picture3 from "../../images/clients/Picture3-1.png"
import Picture4 from "../../images/clients/Picture4-1.png"
import Picture5 from "../../images/clients/Picture5-1.png"
import Picture6 from "../../images/clients/Picture6-1.png"
import Picture8 from "../../images/clients/Picture8-1.png"
import Picture9 from "../../images/clients/Picture9-1.png"
import Picture10 from "../../images/clients/Picture10-1.png"
import Picture11 from "../../images/clients/Picture11-1.png"

const clients = [
  Picture1,
  Picture2,
  Picture3,
  Picture4,
  Picture5,
  Picture6,
  Picture8,
  Picture9,
  Picture10,
  Picture11,
]

export default function OurClients() {
  const ClientItem = ({ children }) => {
    return (
      <WrapItem p={"20px"}>
        <Box
          display={"flex"}
          background={"#0d0d0d"}
          h={"140px"}
          w={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          border={"1px solid rgba(232,232,232,0.05)"}
        >
          {children}
        </Box>
      </WrapItem>
    )
  }
  const ClientImage = ({ src }) => {
    return <Image alt={src} fit={"contain"} align={"center"} src={src} />
  }
  return (
    <Box className="OurClients">
      <Container maxW={"full"}>
        <Box
          background={"#0a0a0a"}
          py={{ base: "20px", md: "80px" }}
          px={{ base: "15px", md: "15px" }}
          borderRadius={"14px"}
        >
          <Flex
            flex={1}
            align={"center"}
            direction={{ base: "column", md: "row" }}
          >
            <Box w={"full"}>
              <Text
                as="h2"
                fontWeight="700"
                // fontSize={"6xl"}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                color={"white"}
                mb={12}
                textAlign={"center"}
              >
                Our Clients & Partners
              </Text>
              <Box>
                <SimpleGrid
                  columns={[1, 2, 3, 4, 5]}
                  spacing={[2, 4, 6, 8]}
                  justifyContent="center"
                >
                  {clients.map(client => {
                    return (
                      <ClientItem>
                        <ClientImage src={client} />
                      </ClientItem>
                    )
                  })}
                </SimpleGrid>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}
