import React from "react"
import {
  Box,
  Heading,
  Container,
  Text,
  Flex,
  Stack,
  Image,
  Button,
  useMediaQuery,
} from "@chakra-ui/react"
import Typewriter from "typewriter-effect"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import { AiOutlineArrowRight } from "react-icons/ai"
import BannerPhoto from "../../images/prosal-hero.png"

const Banner = () => {
  const [isLowerThan768] = useMediaQuery("(max-width: 768px)")
  const [isLowerThan480] = useMediaQuery("(max-width: 480px)")

  return (
    <Container maxW={"full"} marginTop={isLowerThan480 ? "100px" : "50px"}>
      <Box h={isLowerThan768 ? "100vh" : "80vh"}>
        <Swiper
          className="mySwiper"
          auto={true}
          items={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <Stack
              w={"full"}
              maxW={"full"}
              direction={{ base: "column", md: "row" }}
              pt="50px"
            >
              <Flex p={1} flex={1} align={"center"}>
                <Stack spacing={6} w={"full"} maxW={{ base: "full", md: "lg" }}>
                  <Heading
                    fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    textAlign={{ base: "center", md: "left" }}
                  >
                    <Text as={"span"} position={"relative"}>
                      Exclusive Resource
                    </Text>
                    <br />{" "}
                    <Text color={"#04a073"} as={"span"}>
                      <Typewriter
                        options={{
                          strings: [
                            "Design Projects!",
                            "Development",
                            "Marketing",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </Text>{" "}
                  </Heading>
                  <Text
                    fontSize={{ base: "md", lg: "lg" }}
                    color={"gray.500"}
                    textAlign={{ base: "center", md: "left" }}
                  >
                    The project board is an exclusive resource for contract
                    work. It's perfect for freelancers, agencies, and
                    moonlighters. The project board is an exclusive resource for
                    contract work. It's perfect for freelancers, agencies, and
                    moonlighters.
                  </Text>
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <Button
                      rightIcon={<AiOutlineArrowRight />}
                      className="hover-btn green"
                      variant="ghost"
                      color={"#04a073"}
                      fontSize={20}
                      padding={2}
                      _hover={{
                        background: "transparent",
                      }}
                      mt="5"
                      _before={{
                        content: "''",
                        width: "2px",
                        height: "2px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        bg: "#04a073",
                        zIndex: 0,
                        mr: "10px",
                        transition: "width 0.5s ease-in-out",
                      }}
                      _after={{
                        content: "''",
                        width: "100%",
                        height: "2px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        bg: "rgba(81,181,121,0.05)",
                        zIndex: 0,
                        mr: "10px",
                      }}
                    >
                      Our Solutions
                    </Button>
                  </Stack>
                </Stack>
              </Flex>
              <Flex flex={1}>
                <Image
                  alt={"Login Image"}
                  objectFit={"contain"}
                  src={BannerPhoto}
                  width="100%"
                />
              </Flex>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <Box pt={{ base: 20, md: 28 }}>
              <Container maxW={"full"}>
                <Flex
                  flex={1}
                  align={"center"}
                  justify={"space-between"}
                  direction={{ base: "column", md: "row" }}
                  textAlign={{ base: "center", md: "left" }}
                  minH="50vh"
                >
                  <Stack px={{ base: 0, md: 15 }} w={"full"}>
                    <Text
                      as={"h2"}
                      color="dark"
                      lineHeight="1.3"
                      fontWeight={700}
                      fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                      mb={{ base: "20px" }}
                    >
                      Have a Project? <br />
                      Let’s Talk About It
                    </Text>
                  </Stack>
                  <Button
                    rightIcon={<AiOutlineArrowRight />}
                    variant="outline"
                    color={"#04a073"}
                    fontSize="18px"
                    p={"25px"}
                    fontWeight={"bold"}
                    borderWidth="2px"
                    borderColor="#04a073"
                    w={{ base: "xs", md: "md" }}
                    display="flex"
                    justifyContent={"space-between"}
                    _hover={{ borderColor: "#04a073", color: "#04a073" }}
                  >
                    Let's build together
                  </Button>
                </Flex>
              </Container>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Container>
  )
}

export default Banner
